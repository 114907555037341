@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Metropolis';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(234,233,228);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  Metropolis;
}

/* Style the header with a grey background and some padding */
.body-container-lung {
  background: rgb(234,233,228);
}
.body-container-gasto {
  background: #F5F5F5;
}

.header {
  overflow: hidden;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
}

.layout{
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
